import { render, staticRenderFns } from "./Random.vue?vue&type=template&id=6422c628&scoped=true"
import script from "./Random.vue?vue&type=script&lang=js"
export * from "./Random.vue?vue&type=script&lang=js"
import style0 from "./Random.vue?vue&type=style&index=0&id=6422c628&prod&lang=css&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6422c628",
  null
  
)

export default component.exports