var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container-full site-content"},[_c('div',{staticClass:"post"},[(_vm.states.loading)?_c('div',[_c('Loader')],1):_vm._e(),(_vm.error)?_c('div',[_vm._v(" "+_vm._s(_vm.error)+" ")]):_vm._e(),_c('transition',{attrs:{"name":"fade"}},[(_vm.blog)?_c('div',[_c('div',{staticClass:"featured-image-container",style:({
          backgroundImage: `url(${_vm.blog.featuredImageLocation})`,
          backgroundPosition: 'inherit',
          backgroundSize: 'cover',
        })},[_c('div',{staticClass:"blog-details"},[_c('h1',{staticClass:"post-title",style:(_vm.dynamicTitleShadow)},[_vm._v(_vm._s(_vm.blog.title))]),_c('div',{staticClass:"date-container"},[_c('span',{staticClass:"date"},[_vm._v(_vm._s(_vm.dateFormat(_vm.blog.createdAt)))]),_c('span',{staticClass:"read-time"},[_vm._v(_vm._s(_vm.calculateReadTime(_vm.blog.text))+" minute read")])]),_c('div',{staticClass:"like"},[_c('Heart',{attrs:{"slug":_vm.slug}}),_c('div',{staticClass:"rating"},[_vm._v(_vm._s(_vm.rating))])],1),_c('span',{staticClass:"views-container"},[_c('span',{staticClass:"views"},[_vm._v(_vm._s(_vm.blog.views))])])])]),_c('div',{staticClass:"row"},[_c('div',{staticClass:"container-full"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xsmall-12 col-md-2 dir-col align-base blog-categories"},_vm._l((_vm.blog.categories),function(category){return _c('span',{key:category,staticClass:"category-tag"},[_vm._v(_vm._s(category))])}),0),_c('div',{staticClass:"col-xsmall-12 col-md-10 dir-col",domProps:{"innerHTML":_vm._s(_vm.blog.text)}})])])])]),(this.$store.state.isUserLoggedIn)?_c('button',{staticClass:"primary-btn-link",on:{"click":function($event){return _vm.promptUserDelete()}}},[_vm._v(" Delete Post? ")]):_vm._e(),(this.$store.state.isUserLoggedIn)?_c('router-link',{staticClass:"primary-btn-link",attrs:{"to":{ name: 'BlogEdit', params: {
          id:_vm.blog._id,
          slug:_vm.blog.slug
          } }}},[_vm._v(" Edit Post? ")]):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"container-full big-margin-top"},[_c('div',{staticClass:"row space-between"},[(_vm.nextBlog)?_c('div',{staticClass:"old-blog",on:{"click":function($event){return _vm.changeRoute(_vm.nextBlog.slug, _vm.nextBlog._id)}}},[_c('div',{staticClass:"detail-container"},[_c('p',{staticClass:"old-blog-date"},[_vm._v("newer")]),_c('h3',{staticClass:"old-blog-title primary-btn-link"},[_vm._v(" "+_vm._s(_vm.nextBlog.title)+" ")])])]):_vm._e(),(_vm.previousBlog)?_c('div',{staticClass:"old-blog",on:{"click":function($event){return _vm.changeRoute(_vm.previousBlog.slug, _vm.previousBlog._id)}}},[_c('div',{staticClass:"detail-container"},[_c('p',{staticClass:"old-blog-date"},[_vm._v("older")]),_c('h3',{staticClass:"old-blog-title primary-btn-link"},[_vm._v(" "+_vm._s(_vm.previousBlog.title)+" ")])])]):_vm._e()])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }